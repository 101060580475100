<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-card>
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>Order Info</h4>
          </div>
        </b-col>
      </b-row>
      <b-container>
        <b-row cols="3">
          <b-col>
            <label class="col-form-label">Order Name:</label>
            <span class="p-3">{{ form.batchName }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Order No.:</label>
            <span class="p-3">{{ form.number }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Status:</label>
            <span class="p-3">{{ form.statusName }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Production Quantity:</label>
            <span class="p-3">{{ form.batchQuantity }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Normal Quantity:</label>
            <span class="p-3">{{ form.normalQuantity }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Brand Identity:</label>
            <span class="p-3">{{ form.identity }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Brand Name:</label>
            <span class="p-3">{{ form.brandName }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">URL:</label>
            <span class="p-3">{{ form.url }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Max seconds:</label>
            <span class="p-3">{{ form.maxFrequency }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Patch Type:</label>
            <span class="p-3">{{
              form.type == 3 ? "Disposable" : "Replaceable"
            }}</span>
          </b-col>

          <b-col v-if="form.groupNumber">
            <label class="col-form-label">Group Number:</label>
            <span class="p-3">{{ form.groupNumber }}</span>
          </b-col>
          <b-col v-if="form.position">
            <label class="col-form-label">Position:</label>
            <span class="p-3">{{
              form.position == 2 ? "High position" : "Low position"
            }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Create Time:</label>
            <span class="p-3">{{
              form.createTime ? getLocalTimes(form.createTime) : ""
            }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Voltage1:</label>
            <span class="p-3">{{ form.curve }}</span>
          </b-col>
          <b-col v-if="form.curveTwo">
            <label class="col-form-label">Voltage2:</label>
            <span class="p-3">{{ form.curveTwo }}</span>
          </b-col>

          <b-col v-if="form.writeTag">
            <label class="col-form-label">Write Tag:</label>
            <span class="p-3">{{ form.writeTag }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Remarks:</label>
            <span class="p-3">{{ form.remarks }}</span>
          </b-col>
        </b-row>
      </b-container>
      <br />
      <br />
      <b-row
        class="align-items-center"
        v-if="form.status == 5 || form.status == 14 || form.status == 24"
      >
        <b-col lg="12">
          <div class="blockquote">
            <h4>Production Result</h4>
          </div>
        </b-col>
      </b-row>
      <b-container
        v-if="form.status == 5 || form.status == 14 || form.status == 24"
      >
        <b-row cols="3">
          <b-col>
            <label class="col-form-label">Actual Quantity:</label>
            <span class="p-3">{{ form.normalQuantity }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Abnormal Quantity:</label>
            <span class="p-3">{{ form.abnormalQuantity }}</span>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
    <b-button variant="outline-secondary" @click="returnPage">Go Back</b-button>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";

export default {
  components: {
    Layout,
    PageHeader,
    timeZoneConversion,
  },
  data() {
    return {
      title: "Patch Orders Details",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Production Mgmt",
          // href: "/"
        },
        {
          text: "Patch Orders Details",
          active: true,
        },
      ],
      form: {},
      types: {
        statuss: [
          {
            text: "Production information to be confirmed",
            value: 0,
            disabled: false,
          },
          {
            text: "Production information has been confirmed",
            value: 1,
            disabled: false,
          },
          { text: "To be production", value: 1, disabled: false },
          { text: "In Production", value: 2, disabled: false },
          { text: "Completed", value: 3, disabled: false },
        ],
      },
    };
  },
  computed: {},

  created() {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.query.id) {
        this.$api.patchOrder
          .selectByPatchOrderId(this.$route.query)
          .then((res) => {
            if (res.success && res.data) {
              this.form = res.data;
            } else {
              if (res.message) {
                this.$bvToast.toast(res.message);
              }
            }
          });
      } else {
        this.$bvToast.toast("id?");
      }
    },
    formatterStatus(value) {
      let a = "";
      this.types.statuss.filter((item) => {
        if (value === item.value) {
          a = item.text;
        }
      });
      return a;
    },
    returnPage() {
      this.$router.go(-1);
    },
    getLocalTimes(value) {
      return timeZoneConversion.getLocalTimes(value);
    },
  },
};
</script>
<style>
.blockquote {
  padding: 10px 10px;
  border-left: 5px solid #aaaaff;
}
</style>
